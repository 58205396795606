export const AUTH_LOGIN_REQUEST = "@auth/LOGIN_REQUEST"
export const AUTH_LOGIN_SUCCESS = "@auth/LOGIN_SUCCESS"
export const AUTH_LOGIN_FAILURE = "@auth/LOGIN_FAILURE"
export const AUTH_LOGIN_MSG_CLEAR = "@auth/LOGIN_MSG_CLEAR"

export const AUTH_PASSWORD_RESET_REQUEST = "@auth/PASSWORD_RESET_REQUEST"
export const AUTH_PASSWORD_RESET_SUCCESS = "@auth/PASSWORD_RESET_SUCCESS"
export const AUTH_PASSWORD_RESET_FAILURE = "@auth/PASSWORD_RESET_FAILURE"
export const AUTH_PASSWORD_RESET_MSG_CLEAR = "@auth/PASSWORD_RESET_MSG_CLEAR"

export const AUTH_PASSWORD_RESET_CONFIRM_REQUEST =
  "@auth/PASSWORD_RESET_CONFIRM_REQUEST"
export const AUTH_PASSWORD_RESET_CONFIRM_SUCCESS =
  "@auth/PASSWORD_RESET_CONFIRM_SUCCESS"
export const AUTH_PASSWORD_RESET_CONFIRM_FAILURE =
  "@auth/PASSWORD_RESET_CONFIRM_FAILURE"
export const AUTH_PASSWORD_RESET_CONFIRM_MSG_CLEAR =
  "@auth/PASSWORD_RESET_CONFIRM_MSG_CLEAR"

export const AUTH_PASSWORD_CHANGE_REQUEST = "@auth/PASSWORD_CHANGE_REQUEST"
export const AUTH_PASSWORD_CHANGE_SUCCESS = "@auth/PASSWORD_CHANGE_SUCCESS"
export const AUTH_PASSWORD_CHANGE_FAILURE = "@auth/PASSWORD_CHANGE_FAILURE"
export const AUTH_PASSWORD_CHANGE_MSG_CLEAR = "@auth/PASSWORD_CHANGE_MSG_CLEAR"

export const AUTH_INFO_REQUEST = "@auth/INFO_REQUEST"
export const AUTH_INFO_SUCCESS = "@auth/INFO_SUCCESS"
export const AUTH_INFO_FAILURE = "@auth/INFO_FAILURE"

export const AUTH_INFO_UPDATE_REQUEST = "@auth/INFO_UPDATE_REQUEST"
export const AUTH_INFO_UPDATE_SUCCESS = "@auth/INFO_UPDATE_SUCCESS"
export const AUTH_INFO_UPDATE_FAILURE = "@auth/INFO_UPDATE_FAILURE"
export const AUTH_INFO_UPDATE_MSG_CLEAR = "@auth/INFO_UPDATE_MSG_CLEAR"

export const AUTH_REG_REQUEST = "@auth/REG_REQUEST"
export const AUTH_REG_SUCCESS = "@auth/REG_SUCCESS"
export const AUTH_REG_FAILURE = "@auth/REG_FAILURE"
export const AUTH_REG_MSG_CLEAR = "@auth/REG_MSG_CLEAR"

export const AUTH_REG_VERIFY_MAIL_REQUEST = "@auth/REG_VERIFY_MAIL_REQUEST"
export const AUTH_REG_VERIFY_MAIL_SUCCESS = "@auth/REG_VERIFY_MAIL_SUCCESS"
export const AUTH_REG_VERIFY_MAIL_FAILURE = "@auth/REG_VERIFY_MAIL_FAILURE"
export const AUTH_REG_VERIFY_MAIL_MSG_CLEAR = "@auth/REG_VERIFY_MAIL_MSG_CLEAR"

export const AUTH_MODAL_SHOW = "@auth/MODAL_SHOW"
export const AUTH_MODAL_HIDE = "@auth/MODAL_HIDE"

export const AUTH_TOKEN_CHECK_SUCCESS = "@auth/TOKEN_CHECK_SUCCESS"
export const AUTH_TOKEN_CHECK_FAILURE = "@auth/TOKEN_CHECK_FAILURE"
export const AUTH_TOKEN_SAVE = "@auth/TOKEN_SAVE"

export const AUTH_REMEMBER = "@auth/REMEMBER"
export const AUTH_LOGOUT = "@auth/LOGOUT"
